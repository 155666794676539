import Icon from '@elements/Icon';
import Logo from '@elements/Logo';
import { Dialog, Transition } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import Link from 'next/link';
import { Fragment } from 'react';
import { publisherData } from './data';

export default function SlideOut({ showModal, setShowModal, publisher }) {
  const getPublisherText = (publisher) => {
    const publisherName = Array.isArray(publisher) ? publisher[0] : publisher;

    let publisherInfo = publisherData.find((item) => item.publisher === publisherName);

    if (!publisherInfo) {
      publisherInfo = publisherData.find((item) => item.publisher === 'ISSO');
    }

    return publisherInfo ? publisherInfo.text : 'Geen informatie beschikbaar.';
  };

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => setShowModal(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-10 md:w-4/5 lg:w-3/5">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="pointer-events-auto w-full">
              {/* LARGE */}
              <div className="hidden h-full overflow-y-auto bg-white lg:flex">
                <SideLogo publisher={publisher} />
                <MainContent
                  publisher={publisher}
                  text={getPublisherText(publisher)}
                  setShowModal={setShowModal}
                />
              </div>
              {/* MEDIUM */}
              <div className="hidden h-full bg-white md:flex lg:hidden">
                <SideLogo publisher={publisher} />
                <MainContent
                  publisher={publisher}
                  text={getPublisherText(publisher)}
                  setShowModal={setShowModal}
                />
              </div>
              {/* SMALL */}
              <div className="h-screen w-screen overflow-y-auto md:hidden">
                <div className="flex justify-between bg-accent-3 p-4">
                  <div className="my-auto w-[67px]">
                    <SmallLogo publisher={publisher} />
                  </div>
                  <CloseButton setShowModal={setShowModal} />
                </div>
                <div className="h-full overflow-auto bg-white">
                  <SmallMainContent publisher={publisher} text={getPublisherText(publisher)} />
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const getLogo = (publisherName) => {
  switch (publisherName) {
    case 'ISSO':
      return <Logo.isso.svg className="font-black text-primary-dark" />;
    case 'InstallQ':
      return <Logo.installq.svg className="font-black text-primary-dark" />;
    case 'Rijksdienst voor Ondernemend Nederland':
      return <Logo.rvo.svg className="font-black text-primary-dark" />;
    case 'Stichting Kennisbank Bouwfysica':
      return <Logo.bouwzo.svg className="font-black text-primary-dark" />;
    case 'Rijksvastgoedbedrijf':
      return <Logo.rijksvastgoed.svg className="font-black text-primary-dark" />;
    case 'Binnenklimaat Nederland':
      return <Logo.bouwzo.svg className="font-black text-primary-dark" />;
    case 'EPB Center':
      return <Logo.ebpcenter.svg className="font-black text-primary-dark" />;
    default:
      return null;
  }
};

const getWidthClass = (publisherName) => {
  switch (publisherName) {
    case 'Rijksdienst voor Ondernemend Nederland':
    case 'Rijksvastgoedbedrijf':
    case 'EPB Center':
      return 'w-[260px]';
    case 'ISSO':
      return 'w-[160px]';
    default:
      return 'w-[120px]';
  }
};

function SmallLogo({ publisher }) {
  const publisherName = Array.isArray(publisher) && publisher.length ? publisher[0] : publisher;
  const logo = getLogo(publisherName);
  return logo;
}

function SideLogo({ publisher }) {
  const publisherName = Array.isArray(publisher) && publisher.length ? publisher[0] : publisher;
  const logo = getLogo(publisherName);
  const widthClass = getWidthClass(publisherName);

  return (
    <div className="bg-accent-3">
      <div className={classNames(`mx-auto flex h-[80px] items-center px-4 pt-20 ${widthClass}`)}>
        {logo}
      </div>
    </div>
  );
}

function MainContent({ publisher, text, setShowModal }) {
  return (
    <div className="px-8">
      <Header publisher={publisher} setShowModal={setShowModal} />
      <div className="pr-10 pt-6">
        <div className="bg-accent-3 p-6" dangerouslySetInnerHTML={{ __html: text }}></div>
        <hr className="mt-6" />
      </div>
      <ContactInfo />
    </div>
  );
}

function SmallMainContent({ publisher, text }) {
  return (
    <>
      <div className="flex flex-col px-4 pt-4">
        <PublisherHeader publisher={publisher} />
      </div>
      <div className="px-4">
        <div className="my-10 bg-accent-3 px-4 py-6">{text}</div>
        <div className="flex flex-col gap-2">
          <hr />
          <span className="pt-4">Meer vragen over {publisher}?</span>
          <Link href="/contact" className="pb-8 text-accent underline">
            Neem contact op
          </Link>
        </div>
      </div>
    </>
  );
}

function Header({ publisher, setShowModal }) {
  return (
    <div className="flex justify-between pt-[60px]">
      <PublisherHeader publisher={publisher} />
      <CloseButton setShowModal={setShowModal} />
    </div>
  );
}

function PublisherHeader({ publisher }) {
  return (
    <div className="flex flex-col">
      <div className="font-secondary text-lg font-bold text-primary">Kennisleverancier</div>
      <h1 className="pt-2 font-primary text-4xl font-light">{publisher}</h1>
    </div>
  );
}

function CloseButton({ setShowModal }) {
  return (
    <button
      type="button"
      onClick={() => setShowModal(false)}
      className="border-none focus:outline-none"
    >
      <Icon iconName="x" color="text-accent" />
    </button>
  );
}

function ContactInfo() {
  return (
    <div className="flex flex-col gap-2">
      <span className="pt-4">Meer vragen over BouwZo?</span>
      <Link href="/contact" className="pb-8 text-accent underline">
        Neem contact op
      </Link>
    </div>
  );
}
